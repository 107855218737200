// var rev = $('.rev_slider');
// rev.on('init', function(event, slick, currentSlide) {
//   var
//     cur = $(slick.$slides[slick.currentSlide]),
//     next = cur.next(),
//     prev = cur.prev();
//   prev.addClass('slick-sprev');
//   next.addClass('slick-snext');
//   cur.removeClass('slick-snext').removeClass('slick-sprev');
//   slick.$prev = prev;
//   slick.$next = next;
// }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
//   //console.log('beforeChange');
//   var
//     cur = $(slick.$slides[nextSlide]);
//   //console.log(slick.$prev, slick.$next);
//   slick.$prev.removeClass('slick-sprev');
//   slick.$next.removeClass('slick-snext');
//   next = cur.next(),
//     prev = cur.prev();
//   prev.prev();
//   prev.next();
//   prev.addClass('slick-sprev');
//   next.addClass('slick-snext');
//   slick.$prev = prev;
//   slick.$next = next;
//   cur.removeClass('slick-next').removeClass('slick-sprev');
// });

// rev.slick({
//   speed: 1000,
//   arrows: true,
//   dots: false,
//   focusOnSelect: true,
//   prevArrow: '<button> prev</button>',
//   nextArrow: '<button> next</button>',
//   infinite: true,
//   centerMode: true,
//   slidesPerRow: 1,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   centerPadding: '0',
//   swipe: true,
//   customPaging: function(slider, i) {
//     return '';
//   },
//   /*infinite: false,*/
// });


// $('.center').slick({
//   centerMode: true,
//   infinite: true,
//   centerPadding: '60px',
//   slidesToShow: 3,
//   speed: 500,
//   variableWidth: false,
// });
// $('.center').on('beforeChange', function(event, slick, currentSlide, nextSlide){
//   console.log('beforeChange', currentSlide, nextSlide);
// });
// $('.center').on('afterChange', function(event, slick, currentSlide){
//   console.log('afterChange', currentSlide);
// });


//  $(document).ready(function() {

//    setTimeout(() => {

//      // initiate the slider

//      $('.slider').slick({
//        centerMode: true,
//        centerPadding: '10px',
//        slidesToShow: 3,
//        speed: 1500,
//        index: 2,
//        focusOnSelect:true,
//        responsive: [{
//          breakpoint: 768,
//          settings: {
//            arrows: true,
//            centerMode: true,
//            centerPadding: '40px',
//            slidesToShow: 3
//          }
//        }, {
//          breakpoint: 480,
//          settings: {
//            arrows: false,
//            centerMode: true,
//            centerPadding: '40px',
//            slidesToShow: 1
//          }
//        }]
//      });

//      // initiate the slider end

//  },3000);

//  });

// $(function () {
//   $("#aniimated-thumbnials").lightGallery({
//     thumbnail: true
//   });
//   // Card's slider
//   var $carousel = $(".slider-for");

//   $carousel.slick({
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: false,
//     fade: true,
//     adaptiveHeight: true,
//     asNavFor: ".slider-nav"
//   });
//   $(".slider-nav").slick({
//     slidesToShow: 4,
//     slidesToScroll: 1,
//     asNavFor: ".slider-for",
//     dots: false,
//     centerMode: false,
//     focusOnSelect: true,
//     variableWidth: true
//   });
// });
