$(document).ready(function () {
  // console.log("ready!");
  var textcont = document.getElementById("textcont");
  var income = document.getElementById("income");
  var balanced = document.getElementById("balanced");
  var equity = document.getElementById("equity");
  var charity = document.getElementById("charity");
});

function openCity(evt, cityName) {
  var i, tabcontent, tablinks;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  tablinks = document.getElementsByClassName("tablinks");
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}

// function showtext() {
// textcont.style.display="block";
// }

// function hidetext() {
// textcont.style.display="none";
// }
// function showtext1() {
// income.style.display="block";
// }
// function hidetext1() {
// income.style.display="none";
// }
// function showtext2() {
// balanced.style.display="block";
// }
// function hidetext2() {
//   balanced.style.display="none";
// }
// function showtext3() {
// equity.style.display="block";
// }
// function hidetext3() {
// equity.style.display="none";
// }
// function showtext4() {
// charity.style.display="block";
// }
// function hidetext4() {
// charity.style.display="none";
// }
